import { Box, Flex, Stack, Text, useColorMode } from '@chakra-ui/react';
import { Pencil, Trash } from '@phosphor-icons/react';
import moment from 'moment';
import React from 'react';

export const MarkerCard = ({
  isSelected,
  dispatch,
  id,
  longitude,
  latitude,
  radius,
  note,
  totalShops,
  syncedShops,
  lastSync,
  deleteMarker,
  handleSelectMarker,
}) => {
  const { colorMode } = useColorMode();

  const colorModeSwitch = (colorLight, colorDark) => (colorMode === 'light' ? colorLight : colorDark);

  return (
    <Flex
      direction="column"
      border="1px"
      borderBottom="0"
      bg={
        totalShops && syncedShops
          ? totalShops > syncedShops
            ? colorModeSwitch('red.200', 'red.800')
            : totalShops === 50
            ? colorModeSwitch('yellow.200', 'yellow.900')
            : isSelected
            ? colorModeSwitch('orange.200', 'orange.800')
            : ''
          : isSelected
          ? colorModeSwitch('orange.200', 'orange.800')
          : ''
      }
      px="4"
      py="2"
      gap="1"
      opacity={isSelected ? '1' : '0.8'}
      _last={{ borderBottomRadius: 'md', borderBottom: '1px' }}
      _hover={{ opacity: 1 }}
      cursor="pointer"
      onClick={handleSelectMarker}>
      <Text>
        <b>
          {longitude}, {latitude}
        </b>
        ; Radius: {radius}m
      </Text>
      {note && (
        <Text mb="1">
          <b>Kommentar: </b>
          {note}
        </Text>
      )}
      {totalShops && syncedShops ? (
        <Text fontSize="sm">
          Gesamtergebnisse: {totalShops}
          {totalShops === 50 ? ' (!!!)' : ''}; Ergebnisse in DB: {syncedShops}
        </Text>
      ) : null}
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="xs" fontStyle="italic">
          Zuletzt aktualisiert: {moment(lastSync).format('DD.MM.YYYY, HH:mm')}
        </Text>
        <Stack direction="row" spacing="2" height="6" alignItems="center">
          <Box as={Pencil} w="5" h="5" />
          <Box as={Trash} w="5" h="5" color="red.500" onClick={() => deleteMarker(id)} />
        </Stack>
      </Flex>
    </Flex>
  );
};
